import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';
import { propTypes as linkPropTypes } from '../../../components/01_atoms/Link';
import Link from '../../Link';
import ArrowRightIcon from '../../../public/static/icons/arrow-right-gifts.svg';
import MinusIcon from '../../../public/static/icons/minus.svg';
import PlusIcon from '../../../public/static/icons/plus-gifts.svg';
import FormattedText from '../../../components/01_atoms/FormattedText';

import styles from './index.module.scss';

const BBFAQ = ({ items, heading = '', link = null }) => (
  <div className={`bb ${styles['bb-faq']}`}>
    <div className="container">
      <div className={styles['bb-faq__background']}>
        <h3 className={styles['bb-faq__heading']}>{heading}</h3>
        <Accordion
          className={styles['bb-faq__items']}
          allowMultipleExpanded={true}
          allowZeroExpanded={true}
          preExpanded={['first-item']}
        >
          {items.map((item, index) => (
            <AccordionItem
              key={item.title}
              className={styles['bb-faq__item']}
              uuid={index === 0 ? 'first-item' : null}
            >
              <AccordionItemHeading>
                <AccordionItemButton className={styles['bb-faq__item-button']}>
                  <div>{item.title}</div>
                  <AccordionItemState>
                    {(state) =>
                      state.expanded ? (
                        <MinusIcon className={styles['bb-faq__item-icon']} />
                      ) : (
                        <PlusIcon className={styles['bb-faq__item-icon']} />
                      )
                    }
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={styles['bb-faq__item-description']}>
                <FormattedText text={item.description} />
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
        {link?.nextLink && (
          <div className={styles['bb-faq__link']}>
            <Link {...link.nextLink}>
              <a>
                {link.label}
                <ArrowRightIcon />
              </a>
            </Link>
          </div>
        )}
      </div>
    </div>
  </div>
);

BBFAQ.propTypes = {
  items: PropTypes.array.isRequired,
  link: PropTypes.shape(linkPropTypes),
  heading: PropTypes.string,
};

export default BBFAQ;
